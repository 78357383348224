@font-face {
	font-family: 'Open Sans Hebrew';
	src: url('../assets/fonts/OpenSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans Hebrew';
	src: url('../assets/fonts/OpenSans-Bold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans Hebrew';
	src: url('../assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans Hebrew';
	src: url('../assets/fonts/OpenSans-Light.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

html {
	font-size: 11px;
}

body {
	direction: rtl;
	margin: 0;
	width: 100vw;
	min-height: 100vh;
	background-color: #f8f8f8;
	font-family: 'Open Sans Hebrew';
}

a {
	text-align: right;
	text-decoration: none;
	color: #2c98d2;
}

a:hover {
	text-decoration: underline;
}

.show-on-datagrid-row-hover {
	visibility: hidden;
}

.fade-when-not-hovered {
	opacity: 0.5;
	transition: opacity 0.2s;

	&:hover {
		opacity: 1;
	}
}

.form-text-field {
	padding: 0 !important;
	input,
	.MuiSelect-select {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

.hidden-item {
	visibility: hidden;
}

.hidden-item-wrapper {
	&:hover .hidden-item {
		visibility: visible;
	}
}

.middle-center {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.even-grid-row {
	background-color: transparent;
}

.odd-grid-row {
	background-color: #f8f8f8;
}
